import createCache from '@emotion/cache'

/**
 * Creates a configured Emotion cache for Material UI (MUI).
 *
 * On the client side, it configures an insertion point by creating a meta tag at the top of the `<head>`.
 * This ensures that MUI styles are loaded first, allowing developers to easily override MUI styles
 * with other styling solutions, like CSS modules.
 *
 * The `compat` flag is set to `true` to ensure better compatibility with Server-Side Rendering (SSR)
 * and newer React features when used with MUI. @see https://github.com/emotion-js/emotion/issues/2928
 *
 * @returns An instance of the Emotion cache configured for MUI.
 */
export const createEmotionCache = () => {
  const cache = createCache({
    key: 'mui',
    insertionPoint:
      typeof document !== 'undefined'
        ? document.querySelector<HTMLElement>('meta[name="emotion-insertion-point"]') ?? undefined
        : undefined,
  })

  cache.compat = true

  return cache
}
